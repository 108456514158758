import {
  MainAppModel
} from '../../../models/mainApp'
import {
  CategoryModel
} from '../../../models/category'
const mainAppModel = new MainAppModel();
const categoryModel = new CategoryModel();
import upload from '../../../utils/oss'

var myMixin = {
  data() {
    return {
      loading1: false,
      loading2: false,
      imageUrl1: "",
      imageUrl2: "",
      groupOptions: [],
      firstCateOptions: [],
      secondCateOptions2: [],
    }
  },

  created() {
    mainAppModel.index('', 'all', 1).then(res => {
      this.groupOptions = []
      res.result.forEach(item => {
        this.groupOptions.push({
          'id': item.id,
          'name': item.name
        })
      })
    })
    categoryModel.index('', 1, null, 'all').then(res => {
      this.firstCateOptions = []
      res.result.forEach(item => {
        this.firstCateOptions.push({
          'id': item.id,
          'name': item.name
        })
      })
    })

    // if (this.$route.params.id) {
    //   this._getOption(this.$route.params.id)
    // }
  },

  methods: {
    handleSelectChange1(id) {
      this.form1.resetFields(['second_cate_id']);
      categoryModel.index('', 1, id, 'all').then(res => {
        this.secondCateOptions1 = []
        res.result.forEach(item => {
          this.secondCateOptions1.push({
            'id': item.id,
            'name': item.name
          })
        })
      })
    },

    handleSelectChange2(id) {
      console.log(id)
      this._getOption(id)
    },

    handleOption(id) {
      this.ruleForm.second_cate_id = '请选择二级分类'
      this._getOption(id)
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },

    beforeUpload1(file) {
      this.loading1 = true
      upload(file, "softmall-images", {}).then(res => {
        this.loading1 = false
        if(res) {
          this.imageUrl1 = `https://softmall-images.oss-cn-qingdao.aliyuncs.com/${res}`
          this.ruleForm.icon = `https://softmall-images.oss-cn-qingdao.aliyuncs.com/${res}`
        }
      })
    },

    beforeUpload2(file) {
      this.loading2 = true
      upload(file, "softmall-images", {}).then(res => {
        this.loading2 = false
        if(res) {
          this.imageUrl2 = `https://softmall-images.oss-cn-qingdao.aliyuncs.com/${res}`
          this.ruleForm.f_icon = `https://softmall-images.oss-cn-qingdao.aliyuncs.com/${res}`
        }
      })
    },

    _getOption(id) {
      categoryModel.index('', 1, id, 'all').then(res => {
        this.secondCateOptions2 = []
        this.form2.setFieldsValue({
          'second_cate_id': []
        })
        res.result.forEach(item => {
          this.secondCateOptions2.push({
            'id': item.id,
            'name': item.name
          })
        })
      })
    }
  }
}

export default myMixin