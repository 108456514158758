<template>
  <div>
    <a-tabs type="card" class="sm-mt-20">
      <a-tab-pane key="1" tab="基本信息">
        <a-form
            layout="inline"
            class="form1"
            :form="form1"
            @submit="handleSearch"
        >
          <div class="search-content">
            <a-form-item>
              <a-input
                  v-decorator="['key']"
                  placeholder="请输入软件名称"
                  autocomplete="off"
              >
              </a-input>
            </a-form-item>
            <a-form-item>
              <a-select
                  v-decorator="['first_cate_id']"
                  class="sm-select"
                  placeholder="请选择一级分类"
                  showSearch
                  @change="handleSelectChange1"
              >
                <a-select-option
                    v-for="(item, index) in firstCateOptions"
                    :key="index"
                    :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                  v-decorator="['second_cate_id']"
                  class="sm-select"
                  placeholder="请选择二级分类"
                  showSearch
              >
                <a-select-option
                    v-for="(item, index) in secondCateOptions1"
                    :key="index"
                    :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                  v-decorator="['is_recommend']"
                  class="sm-select"
                  placeholder="是否为推荐项目"
              >
                <a-select-option value="t"> 推荐项目</a-select-option>
                <a-select-option value="f"> 非推荐项目</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item>
              <a-select
                  v-decorator="['is_free']"
                  class="sm-select"
                  placeholder="是否免费"
              >
                <a-select-option value="t"> 免费</a-select-option>
                <a-select-option value="f"> 收费</a-select-option>
              </a-select>
            </a-form-item>
            <!-- <a-form-item>
              <a-select
                v-decorator="['point']"
                class="sm-select"
                placeholder="积分选择"
                showSearch
              >
                <a-select-option
                  :value="item"
                  v-for="(item, index) in pointOptions"
                  :key="index"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item> -->
          </div>
          <div class="search-btn">
            <a-form-item>
              <a-button type="primary" html-type="submit"> 搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="danger" @click="handleClear" html-type="button">
                清空
              </a-button>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="showModal('new')">
                新增软件
              </a-button>
            </a-form-item>
          </div>
        </a-form>
        <a-table
            :columns="columns"
            :dataSource="data"
            :pagination="false"
            :loading="loading"
            class="sft_app"
            :scroll="{ x: true }"
        >
          <template slot="system" slot-scope="text">
            <img
                v-for="(item, index) in text"
                :key="index"
                :src="require('@/assets/img/' + item + '.png')"
                :alt="item"
            />
          </template>
          <template slot="is_recommend" slot-scope="text, record">
            <a-switch :checked="text" @change="onChange1(record)"/>
          </template>

          <template slot="sequence" slot-scope="text, record">
            <div>
              <div
                  v-if="record.isEdit"
                  class="sequence-input-box"
              >
                <a-input-number
                    v-model="record.sequence"
                    :autofocus="true"
                    :controls="false"
                    class="sequence-input"
                    :max="999"
                    size="small"
                />

                <a-icon type="check" class="check-outlined"
                        @click="handleUpdateSequence(record)"/>
              </div>

              <div
                  v-else
                  class="check-outlined-box"
              >
                <span>
                  {{ text }}
                </span>
                  <a-icon type="edit" class="edit-outlined"
                          @click="handleClickSequence(record)"/>
              </div>
            </div>
          </template>

          <template slot="visible" slot-scope="text, record">
            <a-switch :checked="text" @change="onChange2(record)"/>
          </template>
          <template slot="is_free" slot-scope="text, record">
            <a-switch :checked="text" @change="onChange3(record)"/>
          </template>
          <span slot="action" slot-scope="text, record">
            <a href="javascript:;" @click="getDetail(record)">维护</a>
            <a-divider type="vertical"/>
            <a href="javascript:;" @click="handleShow(record)" class="green"
            >预览</a
            >
            <a-divider type="vertical"/>
            <a href="javascript:;" @click="handleDelete(record)" class="delete"
            >删除</a
            >
          </span>
        </a-table>
        <Pagination
            :current="current"
            :total="total"
            @handleChange="handleChange"
            v-show="total > 20"
        />
      </a-tab-pane>
      <a-tab-pane key="2" tab="积分分类">
        <point-analysis></point-analysis>
      </a-tab-pane>
    </a-tabs>
    <a-modal
        title="新增"
        v-model="visible"
        @ok="handleOk"
        @cancel="handleCancel"
        okText="确认"
        cancelText="取消"
        width="450px"
    >
      <a-form
          :form="form2"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 18 }"
      >
        <a-form-item label="软件名称">
          <a-input
              autocomplete="off"
              placeholder="请输入软件名称"
              v-decorator="[
              'name',
              { rules: [{ required: true, message: '请输入软件名称!' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="简要描述">
          <a-textarea
              autocomplete="off"
              placeholder="请输入软件简要描述"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-decorator="[
              'description',
              { rules: [{ required: true, message: '请输入软件简要描述!' }] },
            ]"
          />
        </a-form-item>
        <a-form-item label="软件图标">
          <span class="redFont"
          >支持图片格式：PNG、JPG、GIF、JPEG，10M以内，推荐图标尺寸64*64px</span
          >
          <a-upload
              name="icon"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload1"
          >
            <img v-if="imageUrl1" :src="imageUrl1" class="icon" alt="avatar"/>
            <div v-else>
              <a-icon :type="loading1 ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
          <a-upload
              name="f_icon"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :before-upload="beforeUpload2"
          >
            <img
                v-if="imageUrl2"
                :src="imageUrl2"
                class="f_icon"
                alt="avatar"
            />
            <div v-else>
              <a-icon :type="loading2 ? 'loading' : 'plus'"/>
              <div class="ant-upload-text">点击上传</div>
            </div>
          </a-upload>
          <div class="clearfix"></div>
          <div class="upload-word">
            <span>软件图标—正常</span>
            <span>软件图标—焦点</span>
          </div>
        </a-form-item>
        <a-form-item label="软件版本号">
          <a-input
              autocomplete="off"
              v-decorator="[
              'version',
              { rules: [{ required: true, message: '请输入版本号!' }] },
            ]"
              placeholder="请输入版本号"
          />
        </a-form-item>
        <a-form-item label="软件授权">
          <a-select
              v-decorator="[
              'auth_type',
              {
                rules: [{ required: true, message: '请选择免费或者付费软件' }],
              },
            ]"
              placeholder="请选择软件"
              showSearch
          >
            <a-select-option value="free"> 免费软件</a-select-option>
            <a-select-option value="fee"> 收费软件</a-select-option>
          </a-select>
        </a-form-item>
        <!-- <a-form-item label="系统进制">
          <a-select
            v-decorator="[
                'bit',
                { rules: [{ required: true, message: '请选择进制' }] },
              ]"
            placeholder="请选择进制"
            showSearch
          >
            <a-select-option value="32">32位</a-select-option>
            <a-select-option value="64">64位</a-select-option>
          </a-select>
        </a-form-item> -->
        <a-form-item label="所属分组">
          <a-select
              v-decorator="['main_app_id', { rules: [] }]"
              placeholder="请选择软件分组"
              showSearch
          >
            <a-select-option
                v-for="(item, index) in groupOptions"
                :key="index"
                :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="软件分类">
          <a-select
              class="sl-select"
              style="marginright: 10%"
              v-decorator="['first_cate_id', { rules: [] }]"
              placeholder="请选择一级分类"
              showSearch
              @change="handleSelectChange2"
          >
            <a-select-option
                v-for="(item, index) in firstCateOptions"
                :key="index"
                :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-select
              class="sl-select"
              v-decorator="['second_cate_id']"
              placeholder="请选择二级分类"
              showSearch
          >
            <a-select-option
                v-for="(item, index) in secondCateOptions2"
                :key="index"
                :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="软件标签">
          <a-textarea
              v-decorator="['label', { rules: [] }]"
              placeholder="请为软件打上标签，每个标签不超过4个字，标签之间用顿号隔开，便签数量不超过10个"
              autocomplete="off"
              :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import myMixin from "./mixin";
import {mapMutations} from "vuex";
import {SoftlistsModel} from "../../../models/softlists";
import Pagination from "../../../components/Pagination";
import formatTime from "../../../utils/formatTime";
import PointAnalysis from "./PointAnalysis";

const softlistsModel = new SoftlistsModel();
const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
    fixed: "left",
  },
  {
    title: "名称",
    dataIndex: "name",
    key: "name",
    fixed: "left",
  },
  {
    title: "高速下载积分",
    dataIndex: "point",
    key: "point",
  },
  {
    title: "下载量",
    dataIndex: "download_num",
    key: "download_num",
  },
  {
    title: "点赞量",
    dataIndex: "like_count",
    key: "like_count",
  },
  {
    title: "评分",
    dataIndex: "score",
    key: "score",
  },
  {
    title: "版本号",
    dataIndex: "version",
    key: "version",
  },
  {
    title: "软件授权",
    dataIndex: "auth_type",
    key: "auth_type",
  },
  {
    title: "一级分类",
    dataIndex: "first_cate_id",
    key: "first_cate_id",
  },
  {
    title: "二级分类",
    dataIndex: "second_cate_id",
    key: "second_cate_id",
  },
  {
    title: "所属分组",
    dataIndex: "main_app_id",
    key: "main_app_id",
  },
  {
    title: "支持操作系统",
    dataIndex: "system",
    key: "system",
    scopedSlots: {customRender: "system"},
  },
  {
    title: "更新日期",
    dataIndex: "updated_at",
    key: "updated_at",
  },
  {
    title: "是否为推荐项",
    dataIndex: "is_recommend",
    key: "is_recommend",
    scopedSlots: {customRender: "is_recommend"},
  },
  {
    title: "推荐排序",
    dataIndex: "sequence",
    key: "sequence",
    scopedSlots: {customRender: "sequence"},
  },
  {
    title: "是否显示",
    dataIndex: "visible",
    key: "visible",
    scopedSlots: {customRender: "visible"},
  },
  {
    title: "是否免费",
    dataIndex: "is_free",
    key: "is_free",
    scopedSlots: {customRender: "is_free"},
  },
  {
    title: "操作",
    dataIndex: "",
    key: "action",
    scopedSlots: {
      customRender: "action",
    },
    fixed: "right",
  },
];
export default {
  mixins: [myMixin],

  data() {
    return {
      form1: this.$form.createForm(this, {
        name: "horizontal_login",
      }),
      data: [],
      columns,
      visible: false,
      formLayout: "horizontal",
      form2: this.$form.createForm(this, {name: "coordinated"}),
      flag: "",
      loading1: "",
      loading2: "",
      editId: 0,
      loading: false,
      total: 0,
      current: 1,
      secondCateOptions1: [],
      pointOptions: [],
    };
  },

  created() {
    this.pointOptions = [];
    for (let i = 1; i < 151; i++) {
      this.pointOptions.push(i);
    }
    this.querySoftList();
  },

  methods: {
    handleShow(record) {
      location.href =
          process.env.VUE_APP_PORT + "/apps/" + record.id + "?type=all";
    },

    handleSearch(e) {
      e.preventDefault();
      this.form1.validateFields((err, values) => {
        if (!err) {
          let key = values.key ? values.key.trim() : "";
          this.querySoftList(
              key,
              values.first_cate_id,
              values.second_cate_id,
              values.is_recommend,
              values.is_free,
              values.point,
              null,
              1,
              ""
          );
        }
      });
    },

    handleClear() {
      this.form1.resetFields();
      this.querySoftList();
    },

    handleClickSequence(record) {
      record.isEdit = true
    },

    handleUpdateSequence(record) {
      softlistsModel
          .updateSequence({sequence: record.sequence}, record.id)
          .then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
            }
            this.querySoftList();
          });
    },

    onChange1(record) {
      softlistsModel
          .update({is_recommend: !record.is_recommend}, record.id)
          .then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              // this.querySoftList();
              record.is_recommend = !record.is_recommend;
            }
          });
    },

    onChange2(record) {
      softlistsModel
          .update({visible: !record.visible}, record.id)
          .then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              // this.querySoftList();
              record.visible = !record.visible;
            }
          });
    },

    onChange3(record) {
      softlistsModel
          .update({is_free: !record.is_free}, record.id)
          .then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              record.is_free = !record.is_free;
            }
          });
    },

    getDetail(record) {
      this.$router.push({
        path: `/board/softlists/${record.id}`,
        query: {name: `${record.name}`},
      });
      this.parentName(record.name);
    },

    handleDelete(record) {
      const confirmText = ["确定删除当前软件吗？", "删除后将不可恢复"];
      const newDatas = [];
      const h = this.$createElement;
      for (const i in confirmText) {
        newDatas.push(h("p", null, confirmText[i]));
      }
      this.$confirm({
        content: h("div", null, newDatas),
        okText: "确认",
        cancelText: "取消",
        onOk: () => {
          softlistsModel.destroy(record.id).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.querySoftList();
            }
          });
        },
        onCancel() {
        },
      });
    },

    showModal(flag) {
      this.flag = flag;
      this.visible = true;
      this.imageUrl1 = "";
      this.imageUrl2 = "";
    },

    handleChange(page) {
      this.current = page;
      this.form1.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          this.querySoftList(
              values.key,
              values.first_cate_id,
              values.second_cate_id,
              values.is_recommend,
              values.is_free,
              values.point,
              null,
              page,
              ""
          );
        }
      });
    },

    handleOk(e) {
      e.preventDefault();
      this.form2.validateFields((err, values) => {
        if (!err) {
          values.icon = this.imageUrl1;
          values.f_icon = this.imageUrl2;
          softlistsModel.create(values).then((res) => {
            if (res.code == 0) {
              this.visible = false;
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2,
              });
              this.querySoftList();
            }
          });
          this.form2.resetFields();
        }
      });
    },

    handleCancel() {
      this.form2.resetFields();
      this.imageUrl2 = "";
      this.imageUrl1 = "";
    },

    querySoftList(
        key,
        first_cate,
        second_cate,
        recommend,
        is_free,
        point,
        id,
        page,
        all
    ) {
      this.loading = true;
      softlistsModel
          .index(
              key,
              first_cate,
              second_cate,
              recommend,
              is_free,
              point,
              id,
              page,
              all
          )
          .then((res) => {
            if (res.code == 0) {
              this.current = page;
              res.result.map((item, index) => {
                let arr = [];
                item.isEdit = false;
                item.key = index;
                item.updated_at = formatTime(item.updated_at);
                if (item.auth_type == "free") {
                  item.auth_type = "免费软件";
                } else {
                  item.auth_type = "收费软件";
                }
                if (item.first_name && item.first_name.length > 0) {
                  item.first_cate_id = item.first_name[0].name;
                } else {
                  item.first_cate_id = "";
                }
                if (item.second_name && item.second_name.length > 0) {
                  item.second_cate_id = item.second_name[0].name;
                } else {
                  item.second_cate_id = "";
                }

                if (item.main_app && item.main_app.length > 0) {
                  item.main_app_id = item.main_app[0].name;
                } else {
                  item.main_app_id = "";
                }

                item.score = item.score
                    ? Number(item.score).toFixed(1)
                    : item.score;
                item.versions.forEach(function (ctem) {
                  arr.push(ctem.system);
                });
                item.system = [...new Set(arr)];
                return item;
              });
              this.data = res.result;
              this.loading = false;
              this.total = res.total;
            }
          });
    },

    ...mapMutations({
      parentName: "PARENT_NAME",
    }),
  },

  components: {
    Pagination,
    PointAnalysis,
  },
};
</script>

<style lang="scss">
.ant-upload-picture-card-wrapper {
  width: 110px;
  margin-right: 40px;
}

.avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
  float: left;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.clearfix {
  clear: both;
}

.upload-word span {
  width: 110px;
  display: inline-block;
  text-align: center;
  margin-right: 40px;
  font-size: 13px;
  color: #333;
}

.ant-form-item {
  margin-bottom: 10px;
}

.sl-select {
  width: 45% !important;
}

.f_icon,
.icon {
  width: 64px;
}

td img {
  width: 20px;
  margin-right: 5px;
}

@media (max-width: 1650px) {
  .search-content {
    width: 100%;
  }

  .search-btn .ant-form-item:nth-of-type(3),
  .search-content .ant-form-item:nth-of-type(6) {
    margin-right: 0;
  }

  .search-btn {
    float: right;
  }
}

@media (min-width: 1651px) {
  .form1 {
    display: flex;
    align-items: end;
    justify-content: end;
  }

  .search-content {
    float: left;
  }

  .search-btn {
    float: left;
  }
}

.edit-outlined {
  display: none;
}

.check-outlined {
  display: none;
}

.sequence-input-box:hover {
  .check-outlined {
    display: inline-block;
  }
}

.check-outlined-box:hover {
  .edit-outlined {
    display: inline-block;
  }
}

.sequence-input {
  width: 50%;
}
</style>