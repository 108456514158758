<template>
  <div>
    <a-form layout="inline">
      <a-form-item>
        <a-select
          v-model="defaultOptionValue"
          style="width: 120px"
          @change="handleSelectChange"
        >
          <a-select-option v-for="option in selectOptions" :key="option.value">
            {{ option.value }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <ve-pie
      :data="chartData"
      :settings="settings"
      height="500px"
      :legend="legend"
    ></ve-pie>
  </div>
</template>

<script>
import { SoftlistsModel } from "../../../models/softlists";
const softlistsModel = new SoftlistsModel();
export default {
  name: "PointAnalysis",
  data() {
    return {
      chartData: {
        columns: ["point", "count"],
        rows: [],
      },
      settings: {
        label: "",
        radius: 150,
        offsetY: 250,
        // limitShowNum: 30,
      },
      legend: {
        // type: 'scroll',
        orient: "vertical",
        right: "3%",
        top: "5%",
        bottom: 50,
        data: [],
        loading: false,
      },
      defaultOptionValue: 10,
      selectOptions: [
        { value: 10 },
        { value: 20 },
        { value: 30 },
        { value: 40 },
        { value: 50 },
      ],
    };
  },

  mounted() {
    console.log(this.defaultOptionValue, 999);
    this.fetchChartData();
  },

  methods: {
    handleSelectChange(value) {
      this.defaultOptionValue = value;
      this.fetchChartData(value);
    },

    fetchChartData() {
      this.loading = true;
      softlistsModel.getPointChartData(this.defaultOptionValue).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          res.data.forEach((element) => {
            element.point = String(element.point);
            this.legend.data.push(String(element.point));
          });
          this.chartData.rows = res.data;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>